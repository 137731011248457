<template>
  <div>
    <div v-if="isShow == 0">
      <bread-crumb :crumbData="['采购入库单']" :chooseCrumbData="1"></bread-crumb>

      <el-row style="padding: 20px;display: flex; align-items: center;border-bottom: 1px solid #e5e5e5;">
        <el-col :span="6">
          <span>创建时间：</span>
          <el-date-picker v-model="t1" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-col>
        <el-col :span="2">
          <el-select v-model="query.supplier" clearable placeholder="供应商">
            <el-option v-for="item in supplierList" :key="item" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <!-- <el-col :span="2">
        <el-select v-model="query.supplier" clearable placeholder="仓库店铺">
          <el-option v-for="item in supplierList" :key="item" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-col> -->
        <el-col :span="2">
          <el-select v-model="query.status" clearable placeholder="订单状态">
            <el-option v-for="item in statusList" :key="item" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <el-input v-model="query.search" placeholder="输入要查询的站点" style="margin-right: 3px;"></el-input>
          <el-button type="success" size="mini" @click="handleQuery()">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
              <span>查询</span>
            </div>
          </el-button>
        </el-col>
      </el-row>

      <div style="
        padding: 10px 20px;
        border-bottom: 1px solid #eee;
        display: flex;
        align-items: center;
      ">
        <div style="margin-right: 5px">
          <el-button type="primary" size="mini" @click="dialogVisible = true">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
              <span style="margin-left: 3px">新增入库</span>
            </div>
          </el-button>
        </div>
      </div>

      <div style="padding: 20px">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border
          @selection-change="handleSelectionChange" style="width: 100%">
          <el-table-column type="selection" width="44"> </el-table-column>
          <el-table-column prop="create_time" sortable label="入库日期"></el-table-column>
          <el-table-column prop="id" sortable label="编号"></el-table-column>
          <el-table-column prop="commercial_name" sortable label="站点名"></el-table-column>
          <el-table-column prop="supplier_name" sortable label="供应商"></el-table-column>
          <el-table-column prop="warehouse_shop" sortable label="仓库店铺"></el-table-column>
          <el-table-column prop="total_quantity" sortable label="总数量"></el-table-column>
          <el-table-column prop="total_amount" sortable label="总金额"></el-table-column>
          <el-table-column prop="operator" sortable label="操作人"></el-table-column>
          <el-table-column prop="order_reson" sortable label="备注"></el-table-column>
          <!-- <el-table-column prop="status" sortable label="状态"></el-table-column> -->
          <el-table-column label="状态">
            <template slot-scope="scope">
              {{ statusList.find(el => el.value == scope.row.status).label }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="primary" @click="detail(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination :handleQuery="handleQuery" :currentPage="query.currentPage" :currentLength="query.currentLength"
          :total="total">
        </pagination>
      </div>
      <el-dialog title="新增入库" :visible.sync="dialogVisible" width="70%" :before-close="handleClose" append-to-body>
        <add-godown-entry></add-godown-entry>
      </el-dialog>
    </div>
    <Detail ref="detail" v-if="isShow == 1" :onClose="onClose"></Detail>
  </div>
</template>
<script>
import addGodownEntry from "./childrens/addGodownEntry.vue";
import pagination from "@/components/pagination/index.vue";
import { getDate } from '../../initialValue1/label5_interActive/chiledrens/utilse';
import Detail from "./childrens/detail.vue";
export default {
  components: { addGodownEntry, pagination, Detail },
  data() {
    return {
      t1: "",
      commercial_id: localStorage.getItem("commercial_id") ||
        sessionStorage.getItem("commercial_id"),
      statusList: [
        {
          label: "未入库",
          value: 0,
        }, {
          label: "已入库",
          value: 1
        }
      ],
      supplierList: [],
      total: 0,
      query: {
        commercial_id: localStorage.getItem("commercial_id") ||
          sessionStorage.getItem("commercial_id"),
        search: "",
        firstTime: "",
        lastTime: "",
        status: "",
        supplier: "",
        currentPage: 1,
        currentLength: 10,
      },
      searchData: {
        date: "",
        selectSpan1: "供应商:",
        optionLongName1: "",
        selectLongPlace1: "选择供应商",
        optionLongNamees1: [],
        selectSpan2: "仓库店铺:",
        option2: "",
        selectPlace2: "选择店铺",
        optiones2: [],
        selectSpan3: "订单状态:",
        option3: "",
        selectPlace3: "订单状态",
        optiones3: ["未入库", "已入库"],
        QueryContent: "",
        searchPlace: "输入要查询的内容",
        searchBtn1: true,
      },
      radioBtn: "全部",
      tableData: [],
      multipleSelection: [],
      isResult: false,
      tableFinshNum: 0,
      dialogVisible: false,
      isShow: 0
    };
  },
  watch: {
    t1(n) {
      if (n) {
        this.query.firstTime = getDate(n[0])
        this.query.lastTime = getDate(n[1])
      } else {
        this.query.firstTime = ""
        this.query.lastTime = ""
      }
    }
  },
  async mounted() {
    this.$http.post("/system/shop/lst", { commercial_id: this.commercial_id }).then(res => {
      this.supplierList = res.data.data.map(el => {
        el.label = el.shop_name
        el.value = el.id
        return el;
      })
      console.log(this.supplierList);
    })
    this.initData();
    if (this.tableData.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tableData.length;
    }
  },
  computed: {},
  methods: {
    onClose() {
      this.isShow = 0;
    },
    async initData() {
      let res = (await this.$http.post("/inventory/purchase/lst", { commercial_id: this.commercial_id })).data;
      this.tableData = res.data
      this.total = res.count
      // console.log(res);
    },
    detail(row) {
      this.isShow = 1;
    },
    async handleQuery(page = 1) {
      this.query.currentPage = page;
      console.log(this.query);
      let res = (await this.$http.post("/inventory/purchase/search", this.query)).data
      this.total = res.count
      this.tableData = res.data
    },
    handleRadio() {
      console.log(this.radioBtn, "hh");
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleEdit() { },
    handleDelete() { },

    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
          this.handleQuery()
        })
        .catch(() => { });
    },
  },
};
</script>
<style scoped lang="less">
.radioBtn {
  margin-right: 10px;
}

.badge {
  position: absolute;
  top: -5px;
}

/deep/ .el-radio-button__inner {
  border: 0 !important;
  border-radius: 5px !important;
  background-color: #e6e6e6 !important;
}

/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
  background-color: #409eff !important;
  box-shadow: none !important;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

/deep/ .cell {
  padding: 0 !important;
}

/deep/ .el-dialog__header {
  padding: 5px !important;
}

/deep/ .el-dialog__title {
  font-size: 14px !important;
}

/deep/ .el-dialog__body {
  padding: 10px !important;
}

.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table {
  padding: 20px;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

/deep/ .el-col {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin: 0 5px;
}

/deep/ .cell {
  padding: 0 !important;
}
</style>
