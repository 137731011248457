// 销售订单
import Vue from "vue";
const request = Vue.prototype.$http;
const operator = localStorage.getItem("operator");

// 查询销售订单
export function saleSearch( data) {
  return request.post(
    "/sales/manual_order/sale_search", {
      data: JSON.stringify(data)
    });
}
// 批量发货
export function batchShip( data) {
  return request.post(
    "/sales/manual_order/batch_ship", {
      idArr: data
    });
}
// 关闭订单
export function closeOrder( data) {
  return request.post(
    "/sales/manual_order/close_order", {
      id: JSON.stringify(data),
      operator: operator
    });
}
// 订单发货
export function send( data) {
  return request.post(
    "/sales/manual_order/send", {
      id: JSON.stringify(data),
      operator: operator
    });
}
// 订单退款
export function refund( data) {
  return request.post(
    "/sales/manual_order/refund", {
      id: JSON.stringify(data),
      operator: operator
    });
}


// 订单信息
export function order_details(id) {
  return request.post(
    "/sales/manual_order/order_details",
    {id}
  );
}
// 收款信息
export function order_pay(id) {
  return request.post(
    "/sales/manual_order/order_pay",
    {id}
  );
}

// 退款信息
export function refunds(id) {
  return request.post(
    "/sales/manual_order/refunds",
    {id}
  );
}

// 回桶信息
export function back_barrel(id) {
  return request.post(
    "/sales/manual_order/back_barrel",
    {id}
  );
}

// 订单日志
export function log(id) {
  return request.post(
    "/sales/manual_order/log",
    {id}
  );
}
// 配送站点列表
export function station(id) {
  return request.post(
    "/sales/manual_order/station",
    {id}
  );
}

