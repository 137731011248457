<template>
  <div>
    <div>
      <div style="
        height: 80px;
        border: 1px solid #e5e5ee;
        background-color: #f5fafe;
        display: flex;
        justify-content: space-between;
        margin: 10px 5px;
      ">
        <div style="padding: 10px 20px">
          <p style="font-size: 30px">入库单</p>
          <p style="font-size: 12px">
            日期：<span>{{ date }}</span>
          </p>
        </div>
        <div>
          <el-button type="primary" size="mini" style="margin: 5px" @click="save(0)">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
              <span style="margin-left: 5px">保存</span>
            </div>
          </el-button>
          <el-button type="primary" size="mini" style="margin: 5px" @click="save(1)">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
              <span style="margin-left: 5px">保存并入库</span>
            </div>
          </el-button>
        </div>
      </div>

      <div style="
        height: 80px;
        border: 1px solid #e5e5ee;
        background-color: white;
        margin: 10px 5px;
        padding: 20px;
      ">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-row>
            <el-col :span="10">
              <el-form-item label="供应商：" prop="supplier">
                <el-select v-model="ruleForm.supplier" clearable filterable style="width: 100px">
                  <el-option v-for="item in suppliers" :key="item" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="仓库店铺：">
                <el-select v-model="ruleForm.warehouseShop" clearable filterable style="width: 100px">
                  <el-option v-for="item in warehouseShops" :key="item" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="订单备注：" prop="remark">
            <el-input v-model="ruleForm.remark" style="width: 25%"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div style="
        border: 1px solid #e5e5ee;
        background-color: #f5fafe;
        margin: 20px 5px;
      ">
        <table class="table1">
          <tr>
            <th>序号</th>
            <th>货号</th>
            <th>名称</th>
            <th>桶品牌</th>
            <th>规格/颜色</th>
            <th>数量</th>
            <th>进货价</th>
            <th>金额</th>
            <th>运费(单件)</th>
            <th>操作</th>
          </tr>
          <tr style="height: 20px" v-for="(table, index) in tables" :key="index">
            <td>{{ table.serial }}</td>
            <td>{{ table.number }}</td>
            <td>{{ table.product_name }}</td>
            <td>{{ table.brand }}</td>
            <td>{{ table.specification }}</td>
            <td style="padding:0px 5px"><el-input v-model="table.quantity"></el-input></td>
            <td style="padding:0px 5px;"><el-input v-model="table.purchasing_cost"></el-input></td>
            <td>{{ (isNaN(parseFloat(table.quantity) * parseFloat(table.purchasing_cost)) ? 0 : parseFloat(table.quantity)
              *
              parseFloat(table.purchasing_cost)).toFixed(2) }}
            </td>
            <td>{{ table.quantity * table.freight || 0 }}</td>
            <td>
              <div>
                <i class="el-icon-delete" @click="del(index)" style="curson:pointer"></i>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="10" style="padding: 10px 40px; text-align: left">
              <div>
                <el-input v-model="addGoods" placeholder="请输入商品名称或货号" style="width: 180px; margin-right: 10px"></el-input>
                <el-link icon="el-icon-plus" @click="add">添加商品</el-link>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="5">合计</td>
            <td></td>
            <td>
              {{ totalAmount }}
            </td>
            <td>
              {{ all }}
            </td>
            <td>
              {{ totalFreight
              }}
            </td>
            <td></td>
          </tr>
        </table>
      </div>


      <div>
        <el-form :model="ruleForm1" ref="ruleForm1" label-width="120px" class="demo-ruleForm">
          <el-row>
            <el-col :span="10">
              <el-form-item label="附加费：">
                <el-input v-model="ruleForm1.additionalCharge" style="width: 30%"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="应付款：">
                <span style="color: red">({{ all }}+{{ totalFreight }}+{{ ruleForm1.additionalCharge * 1 }}){{
                  totalFreight * 1
                  + all * 1 + ruleForm1.additionalCharge * 1 }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="现金：">
                <el-input v-model="ruleForm1.cash" style="width: 90px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="微信：">
                <el-input v-model="ruleForm1.weChat" style="width: 90px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="支付宝：">
                <el-input v-model="ruleForm1.Alipay" style="width: 90px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="累计欠款：">
                <span>{{ ruleForm1.accumulatedArrears }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="以往欠款：">
                <span>{{ ruleForm1.pastArrears }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="还款：">
                <span>{{ ruleForm1.refund }}</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <AddProduct :productShow="productShow" @handleClose="handleClose" @addConfirm="addConfirm"
      @productClick="productClick" />
  </div>
</template>
<script>
import AddProduct from "@/components/addProduct/addProduct.vue";
export default {
  components: {
    AddProduct
  },
  data() {
    return {
      productShow: false,
      date: "2023-05-05 15:39:19",
      commercial_id: localStorage.getItem("commercial_id") ||
        sessionStorage.getItem("commercial_id"),
      suppliers: [],
      warehouseShops: [],
      ruleForm: {
        supplier: "",
        warehouseShop: "",
        remark: "",
      },
      rules: {
        supplier: [{ required: true, message: "请选择", trigger: "change" }],
      },
      tables: [],
      addGoods: "",
      ruleForm1: {
        additionalCharge: null,
        cash: null,
        weChat: null,
        Alipay: null,
        accumulatedArrears: null,
        pastArrears: null,
        refund: null,
      },
    };
  },
  async mounted() {
    this.ruleForm = {}
    this.ruleFor1 = {}
    this.tables = [];
    this.suppliers = (await this.$http.post("/inventory/supplier/lst", { commercial_id: this.commercial_id })).data.data.map(el => { return { label: el.supplier_name, value: el.id } })
    this.warehouseShops = (await this.$http.post("/system/shop/lst", { commercial_id: this.commercial_id })).data.data.map(el => { return { label: el.shop_name, value: el.id } });
    console.log(this.suppliers, this.warehouseShops);
  },
  computed: {
    totalAmount() {
      return this.tables.length && this.tables.map(el => Number(el.quantity)).reduce((x, y) => x + y)
    },
    totalFreight() {
      return this.tables.length && this.tables.map(el => Number((el.quantity * el.freight) || 0)).reduce((x, y) => x +
        y)
    },
    all() {
      if (!this.tables.length) return 0
      return (this.tables.reduce((num, table) => {
        let num2 = isNaN(parseFloat(table.quantity) * parseFloat(table.purchasing_cost)) ? 0 : parseFloat(table.quantity) *
          parseFloat(table.purchasing_cost);
        return num + num2
      }, 0)).toFixed(2);
    }
  },
  methods: {
    del(index) {
      this.tables.splice(index, 1)
    },
    add() {
      this.productShow = true
    },
    save(type) {
      this.$http.post("/inventory/purchase/save", {
        supplier_id: this.ruleForm.supplier,
        warehouse_shop: this.ruleForm.warehouseShop,
        order_reson: this.ruleForm.remark,
        product_arr: this.tables,
        additional_charge: this.ruleForm1.additionalCharge || 0,
        cash: this.ruleForm1.cash || 0,
        wechat: this.ruleForm1.weChat || 0,
        alipay: this.ruleForm1.Alipay || 0,
        cumulative_number: 0,
        past_number: 0,
        repayment: 0,
        commercial_id: localStorage.getItem("commercial_id") ||
          sessionStorage.getItem("commercial_id"),
        operator: localStorage.getItem("user_id") ||
          sessionStorage.getItem("user_id"),
        status: type
      });
      this.onClose()

    },
    addConfirm(data) {
      console.log('add', data);
      let arr = data.map(data => {
        return {
          serial: data.product_id,
          "number": data.number,
          "product_name": data.product_name,
          "brand": data.t_name,
          "specification": data.specifications,
          quantity: 0,
          "purchasing_cost": data.sales_price,
          "freight": data.freight,
        }
      })
      this.tables = [...this.tables, ...arr];
      this.productShow = false;
    },
    productClick(data) {
      console.log('click', data);
      let res = {};
      res = {
        serial: data.product_id,
        "number": data.number,
        "product_name": data.product_name,
        "brand": data.t_name,
        "specification": data.specifications,
        quantity: 0,
        "purchasing_cost": data.sales_price,
        "freight": data.freight,
        // allPrice: 0,
      };
      // console.log(res);

      // data.coupon = 0;
      // data.number = 1;
      // data.price = data.sales_price;
      this.tables.push(res)
    },
    handleClose() {
      this.productShow = false
    },
    onClose(data) {
      this.$emit("close", data);
    },
  },
};
</script>
<style lang="less" scoped>
.table1 {
  border-collapse: collapse;
  width: 100%;

  tr {
    th {
      border: 1px solid #e5e5e5;
      padding: 6px 20px;
      white-space: nowrap;
    }

    td {
      border: 1px solid #e5e5e5;
      text-align: center;
      padding: 6px 20px;
      white-space: nowrap;
    }
  }
}
</style>
